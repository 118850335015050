import { Provider } from '@healthhub/api-lib';

import {
  ADD_SERVICE_STEP,
  ADD_BRANCH_STEP,
  SET_SCHEDULE_STEP,
  VIEW_APPOINTMENT_STEP,
  SET_PROFILE_STEP,
  SHOW_SELF_ONBOARDING,
  OnboardingStep,
} from '@mwell-healthhub/commons';
import {
  ProviderOnboardingStatusEnum,
  ProviderOnboardingStepEnum,
} from '@mwell-healthhub/commons/enums';

import { Routes } from '../constants';
import { OnboardingProgressionContextSteps } from '../contexts';

export const progressionStepByRoute: Record<string, OnboardingStep> = {
  [Routes.APPOINTMENTS]: VIEW_APPOINTMENT_STEP,
  [Routes.BRANCHES]: ADD_BRANCH_STEP,
  [Routes.PROFILE]: SET_PROFILE_STEP,
  [Routes.SCHEDULE]: SET_SCHEDULE_STEP,
  [Routes.SERVICES]: ADD_SERVICE_STEP,
};

const allowedRoutes = [Routes.ROOT, Routes.GETTING_STARTED, Routes.AUTH, Routes.ONBOARDING];

const onboardingRoutes = [
  Routes.APPOINTMENTS,
  Routes.BRANCHES,
  Routes.PROFILE,
  Routes.SCHEDULE,
  Routes.SERVICES,
];

export function checkUserMiddlewareRouteAccessByOnboardingProgression(
  provider: Provider,
  route: string,
) {
  if (!isSelfOnboarding(provider)) {
    return true;
  }

  const currentRoute = `/${route.split('/')[1]}`;

  if ([...allowedRoutes, ...onboardingRoutes].includes(currentRoute)) {
    return true;
  }

  return false;
}

export function isSelfOnboarding(provider: Provider) {
  return (
    SHOW_SELF_ONBOARDING &&
    provider?.onboardingStatus === ProviderOnboardingStatusEnum.SELF_ONBOARDED
  );
}

type CheckIfIsLinkToolTipActiveProps = {
  route: string;
  provider: Provider;
  uiStep: number;
  userProgressionStep?: number;
  isReplay?: boolean;
};

export function checkIfIsLinkToolTipActive(props: CheckIfIsLinkToolTipActiveProps) {
  const { route, provider, uiStep, userProgressionStep, isReplay } = props;

  const isRouteAccessible = checkIsAllowedUserRouteAccess({
    route,
    provider,
    uiStep,
    userProgressionStep,
    isReplay,
  });

  const currentRouteProgressionStep = progressionStepByRoute[route]?.step;

  return (
    isRouteAccessible &&
    userProgressionStep == currentRouteProgressionStep &&
    uiStep === OnboardingProgressionContextSteps.SELECT_LINK_STEP
  );
}

type CheckIsAllowedUserRouteAccessProps = {
  route: string;
  provider: Provider;
  uiStep: number;
  userProgressionStep?: number;
  isReplay?: boolean;
};

export function checkIsAllowedUserRouteAccess(props: CheckIsAllowedUserRouteAccessProps) {
  const { route, provider, uiStep, isReplay } = props;
  let { userProgressionStep } = props;

  if (!isSelfOnboarding(provider)) {
    return true;
  }

  if (!userProgressionStep) {
    userProgressionStep = provider?.onboardingProgression?.currentStep ?? 0;
  }

  const currentRoute = `/${route.split('/')[1]}`;

  if (allowedRoutes.includes(currentRoute)) {
    return true;
  }

  if (!checkUserMiddlewareRouteAccessByOnboardingProgression(provider, route)) {
    return false;
  }

  const currentRouteProgressionStep = progressionStepByRoute[currentRoute]?.step;
  const lastStep = ProviderOnboardingStepEnum.AWAITING_APPROVAL;
  const profileStep = SET_PROFILE_STEP.step;

  if (userProgressionStep === 0) {
    return false;
  }

  if (isReplay) {
    return userProgressionStep === currentRouteProgressionStep;
  }

  // user starts specific tutorial step i.e add service, add branch, etc
  const isActiveByProgression = userProgressionStep === currentRouteProgressionStep && uiStep > 0;

  // unlike isActiveByProgression, user can access profile step without manually starting
  const isProfileAccessible =
    userProgressionStep === profileStep && currentRouteProgressionStep === profileStep;

  // completed steps are accessible but only if no active tutorial step is ongoing
  const isCompletedStep =
    userProgressionStep <= lastStep &&
    userProgressionStep > currentRouteProgressionStep &&
    uiStep == 0;

  return isActiveByProgression || isProfileAccessible || isCompletedStep;
}
