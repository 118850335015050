/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeMedicationRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdMedicationDto,
  PatientRecordType,
  UpdateDigitalIdMedicationDto,
  createMedicationDigitalId,
  removeMedicationDigitalId,
  updateMedicationDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateMedicationRecordMutation = (
  mutationOptions: MutationOptions<
    PeMedicationRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdMedicationDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdMedicationDto) =>
      createMedicationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateMedicationRecordMutation = (
  mutationOptions: MutationOptions<
    PeMedicationRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdMedicationDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdMedicationDto) =>
      updateMedicationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveMedicationRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeMedicationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
