/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeMedicalHistoryRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdMedicalHistoryDto,
  PatientRecordType,
  UpdateDigitalIdMedicalHistoryDto,
  createMedicalHistoryDigitalId,
  removeMedicalHistoryDigitalId,
  updateMedicalHistoryDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateMedicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeMedicalHistoryRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdMedicalHistoryDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdMedicalHistoryDto) =>
      createMedicalHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateMedicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeMedicalHistoryRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdMedicalHistoryDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdMedicalHistoryDto) =>
      updateMedicalHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveMedicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeMedicalHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
