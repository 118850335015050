import { RawRule, SubjectRawRule, SubjectType } from '@casl/ability';

export const formatNumber = (number: number, precision?: number): string => {
  const formattedNumber = precision !== undefined ? number.toFixed(precision) : number.toString();
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDigitalIdPermissions = (permissions: string[] = []): RawRule[] => {
  const rules: SubjectRawRule<string, SubjectType, unknown>[] = [];

  permissions.forEach((permission: string) => {
    const [subject, action] = permission.split(':');

    rules.push({
      action,
      subject,
    });
  });

  return rules;
};
