/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeVitalSignRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdVitalSignsDto,
  PatientRecordType,
  UpdateDigitalIdVitalSignsDto,
  createVitalSignsDigitalId,
  removeVitalSignsDigitalId,
  updateVitalSignsDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateVitalSignsRecordMutation = (
  mutationOptions: MutationOptions<
    PeVitalSignRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdVitalSignsDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdVitalSignsDto) =>
      createVitalSignsDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateVitalSignsRecordMutation = (
  mutationOptions: MutationOptions<
    PeVitalSignRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdVitalSignsDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdVitalSignsDto) =>
      updateVitalSignsDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveVitalSignsRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeVitalSignsDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
