import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  updateIdentificationDocument,
  uploadIdentificationDocument,
  UploadIdentificationDocumentDto,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useUploadIdentificationDocument = (
  mutationOptions?: UseMutationOptions<
    void,
    AxiosError<{ message: string }>,
    { patientId: string; uploadIdentificationDocumentDto: UploadIdentificationDocumentDto }
  >,
  digitalIdAccessToken?: string,
) =>
  useMutation(async ({ patientId, uploadIdentificationDocumentDto }) => {
    await uploadIdentificationDocument(
      patientId,
      uploadIdentificationDocumentDto,
      digitalIdAccessTokenConfig(digitalIdAccessToken),
    );
  }, mutationOptions);

export const useUpdateIdentificationDocument = (
  mutationOptions?: UseMutationOptions<
    void,
    AxiosError<{ message: string }>,
    {
      id: string;
      patientId: string;
      uploadIdentificationDocumentDto: UploadIdentificationDocumentDto;
    }
  >,
  digitalIdAccessToken?: string,
) =>
  useMutation(async ({ id, patientId, uploadIdentificationDocumentDto }) => {
    await updateIdentificationDocument(
      id,
      patientId,
      uploadIdentificationDocumentDto,
      digitalIdAccessTokenConfig(digitalIdAccessToken),
    );
  }, mutationOptions);
