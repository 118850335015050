import { PeIdentificationDocument } from '@healthhub/api-lib';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import {
  FindPeIdentificationDocumentsQueryDto,
  getIdentificationDocumentsFromPatient,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useGetIdentificationDocumentQuery = (
  patientId: string,
  query?: FindPeIdentificationDocumentsQueryDto,
  digitalIdAccessToken?: string,
  options?: UseQueryOptions<PeIdentificationDocument[]>,
) => {
  return useQuery<PeIdentificationDocument[]>(
    ['PATIENT_IDENTIFICATION_DOCUMENT_QUERY', patientId],
    () =>
      getIdentificationDocumentsFromPatient(
        patientId,
        query,
        digitalIdAccessTokenConfig(digitalIdAccessToken),
      ),
    options,
  );
};
