/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeVaccinationRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdVaccinationDto,
  PatientRecordType,
  UpdateDigitalIdVaccinationDto,
  createVaccinationDigitalId,
  removeVaccinationDigitalId,
  updateVaccinationDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateVaccinationRecordMutation = (
  mutationOptions: MutationOptions<
    PeVaccinationRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdVaccinationDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdVaccinationDto) =>
      createVaccinationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateVaccinationRecordMutation = (
  mutationOptions: MutationOptions<
    PeVaccinationRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdVaccinationDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdVaccinationDto) =>
      updateVaccinationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveVaccinationRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeVaccinationDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
