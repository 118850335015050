/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeSurgicalHistoryRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdSurgicalHistoryDto,
  PatientRecordType,
  UpdateDigitalIdSurgicalRecordDto,
  createSurgicalRecordDigitalId,
  removeSurgicalRecordDigitalId,
  updateSurgicalRecordDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateSurgicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeSurgicalHistoryRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdSurgicalHistoryDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdSurgicalHistoryDto) =>
      createSurgicalRecordDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateSurgicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeSurgicalHistoryRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdSurgicalRecordDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdSurgicalRecordDto) =>
      updateSurgicalRecordDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveSurgicalHistoryRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeSurgicalRecordDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
