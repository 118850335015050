/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';

import {
  OTP_TOKEN,
  UseMutationOptionsType,
  checkHasOtpTokenValueExpired,
  checkIsCurrentUser,
} from '@mwell-healthhub/commons';

import { sendEmailOtp } from '../../services/userVerificationService';

type LoginData = {
  email: string;
  password: string;
};

export const useLogin = (auth: Auth, mutationOptions?: UseMutationOptionsType) =>
  useMutation(async (data: LoginData) => {
    const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
    const idTokenResult = await userCredential.user.getIdTokenResult();

    await fetch('/api/login', {
      headers: {
        Authorization: `Bearer ${idTokenResult.token}`,
      },
    });

    const otpToken = Cookies.get(OTP_TOKEN) as string;
    const hasExpired = otpToken && checkHasOtpTokenValueExpired(otpToken);
    const isSameUser = otpToken && checkIsCurrentUser(otpToken, data.email);

    if (!otpToken || hasExpired || !isSameUser) {
      await sendEmailOtp();
    }
  }, mutationOptions);

export const useUserLogout = (auth: Auth, mutationOptions?: UseMutationOptionsType) =>
  useMutation(async () => {
    await auth.signOut();
    await fetch('/api/logout');

    window.location.reload();
  }, mutationOptions);
