/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeAllergyRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdAllergyDto,
  PatientRecordType,
  UpdateDigitalIdAllergyDto,
  createAllergyDigitalId,
  removeAllergyDigitalId,
  updateAllergyDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateAllergyRecordMutation = (
  mutationOptions: MutationOptions<
    PeAllergyRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdAllergyDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdAllergyDto) =>
      createAllergyDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateAllergyRecordMutation = (
  mutationOptions: MutationOptions<
    PeAllergyRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdAllergyDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdAllergyDto) =>
      updateAllergyDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveAllergyRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeAllergyDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
