import { Routes } from '@mwell-healthhub/commons';

export const digitalIdAccessTokenConfig = (digitalIdAccessToken?: string) => {
  if (!digitalIdAccessToken) {
    return {};
  }

  return {
    headers: {
      'x-digital-id-patient-record-access-token': digitalIdAccessToken,
    },
  };
};

export const redirectToDigitalIdPatientRecord = (id?: string) => {
  if (id) {
    return Routes.DIGITAL_IDS_BY_ID.replace(':id', id);
  }

  return Routes.DIGITAL_IDS;
};
