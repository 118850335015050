/* eslint-disable @typescript-eslint/no-explicit-any */
import { PeSocialHistoryRecord } from '@healthhub/api-lib';
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  CreateDigitalIdSocialHistoryDto,
  PatientRecordType,
  UpdateDigitalIdSocialHistoryDto,
  createSocialHistoryDigitalId,
  removeSocialHistoryDigitalId,
  updateSocialHistoryDigitalId,
} from '../../services';
import { digitalIdAccessTokenConfig } from '../../utils';

export const useCreateSocialHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeSocialHistoryRecord,
    AxiosError<{ message: string }>,
    CreateDigitalIdSocialHistoryDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: CreateDigitalIdSocialHistoryDto) =>
      createSocialHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useUpdateSocialHistoryRecordMutation = (
  mutationOptions: MutationOptions<
    PeSocialHistoryRecord,
    AxiosError<{ message: string }>,
    UpdateDigitalIdSocialHistoryDto
  >,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: UpdateDigitalIdSocialHistoryDto) =>
      updateSocialHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};

export const useRemoveSocialHistoryRecordMutation = (
  mutationOptions: MutationOptions<void, AxiosError<{ message: string }>, PatientRecordType>,
  digitalIdAccessToken?: string,
) => {
  return useMutation(
    (data: PatientRecordType) =>
      removeSocialHistoryDigitalId(data, digitalIdAccessTokenConfig(digitalIdAccessToken)),
    mutationOptions,
  );
};
